import { LOCALES } from '@/i18nConfig';
import { ROUTES } from '@/routes';
import { APP_LOCALE_HEADER } from '@/app/api/consts';
import { COUNTRY_TO_LOCALE_MAP } from '@/consts/locales';

import { Location } from './types';

import { getDefaultLocation, getLocationByIpAddress, tryGetBrowserLocation } from '.';

export type LocationFeesResponse = {
  error?: string;
  result: Location | null;
  isLoading?: boolean;
};

export type LocationsResponse = {
  error?: string;
  result: Location[];
};

export const getLocationFees = async (lang: string): Promise<LocationFeesResponse> => {
  const res = await fetch(ROUTES.APILocationsFees || '', {
    headers: {
      [APP_LOCALE_HEADER]: lang,
    },
  });

  if (!res.ok) {
    return {
      result: null,
      error: 'Failed to fetch data',
    };
  }

  try {
    const resJSON = await res.json();
    const location: Location = resJSON.body;

    if (!location) {
      return {
        result: null,
        error: 'Missing locations',
      };
    }

    return {
      result: {
        ...location,
        googleMapUrl: location.description.split(';')[1],
        fullAddress: ROUTES.fullAddress(location.location),
      },
    };
  } catch {
    return {
      result: null,
      error: 'Failed to fetch data',
    };
  }
};

export const getLocations = async (lang: string): Promise<LocationsResponse> => {
  const res = await fetch(ROUTES.APILocations || '', {
    headers: {
      [APP_LOCALE_HEADER]: lang,
    },
  });

  if (!res.ok) {
    return {
      result: [],
      error: 'Failed to fetch data',
    };
  }

  try {
    const resJSON = await res.json();
    const allLocations: Location[] = resJSON.body;

    if (!allLocations.length) {
      return {
        result: [],
        error: 'Missing locations',
      };
    }

    const mappedData = allLocations.map((item) => ({
      ...item,
      googleMapUrl: item.description?.split(';')[1],
      fullAddress: ROUTES.fullAddress(item.location),
    }));

    switch (lang) {
      // AU and NZ use the same endpoint, so we need to filter them
      case LOCALES.EN_AU:
      case LOCALES.EN_NZ:
        return {
          result: mappedData.filter((item) => {
            const countryLocale = COUNTRY_TO_LOCALE_MAP[item.location.country];

            return countryLocale === lang;
          }),
        };
      default:
        return {
          result: mappedData,
        };
    }
  } catch {
    return {
      result: [],
      error: 'Failed to fetch data',
    };
  }
};

export const getUserLocation = async (lang: string) => {
  const browserLocation = await tryGetBrowserLocation();
  const ipLocation = !browserLocation ? await getLocationByIpAddress() : null;
  const location = browserLocation || ipLocation || getDefaultLocation(lang);

  return {
    location,
    isFromBrowser: Boolean(browserLocation),
  };
};
