import * as prismic from '@prismicio/client';
import * as prismicNext from '@prismicio/next';

import config from './slicemachine.config.json';

/**
 * The project's Prismic repository name.
 */
export const repositoryName = config.repositoryName;

/**
 * A list of Route Resolver objects that define how a document's `url` field is resolved.
 *
 * {@link https://prismic.io/docs/route-resolver#route-resolver}
 */
// TODO: Update the routes array to match your project's route structure.
const routes: prismic.ClientConfig['routes'] = [
  {
    type: 'homepage',
    path: '/:lang?',
  },
  {
    type: 'blog_post',
    path: '/:lang?/blog/:uid',
  },
  {
    type: 'guide',
    path: '/:lang?/guide/:uid',
  },
  {
    type: 'page',
    path: '/:lang?/:uid',
  },
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config - Configuration for the Prismic client.
 */
export const createClient = (config: prismicNext.CreateClientConfig = {}) => {
  const client = prismic.createClient(repositoryName, {
    routes,
    // TODO: revalidate 5 is temporary solution, we should rebuild pages on Prismic changes
    fetchOptions: { next: { tags: ['prismic'], revalidate: 60 * 10 } },
    ...config,
  });

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
};
