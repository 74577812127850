import { LOCALES } from '@/i18nConfig';

export const LOCALES_MAP: Record<string, string> = {
  [LOCALES.EN_CA]: 'Canada (EN)',
  [LOCALES.EN_AU]: 'Australia (EN)',
  [LOCALES.EN_NZ]: 'New Zealand (EN)',
  [LOCALES.EN_HK]: 'Hong Kong (EN)',
  [LOCALES.FR_CA]: 'Canada (FR)',
  ...(LOCALES.ZH_HK ? { [LOCALES.ZH_HK]: 'Hong Kong (ZH)' } : {}),
};

export const COUNTRY_TO_LOCALE_MAP: Record<string, string> = {
  Canada: LOCALES.EN_CA,
  Australia: LOCALES.EN_AU,
  'New Zealand': LOCALES.EN_NZ,
  'Hong Kong': LOCALES.EN_HK,
};
